import React, { useState, useEffect } from "react";
import { Col, Row, Button, message,Popover } from "antd";
import { useSelector } from 'react-redux';
import Header from '../../component/pageheader/pageHeader';
import { useHistory } from 'react-router-dom'
import { bookcover, bookpath, saveandremovefav, bookdetails,favoriteURL } from '../../api/api';
import {ReadOutlined,FilePdfOutlined,FilterOutlined,LikeFilled,LikeOutlined,LoadingOutlined} from '@ant-design/icons';
import "antd/dist/antd.css";
import './styles.css';

const Description = () => {
    const history = useHistory();
    const nova_userCredentials = JSON.parse(localStorage.getItem('nova_userCredentials'));
    const selecteditem = useSelector((state) => state.App.selecteditem);
    const [description, setdescription] = useState('');
    const [loadings, setloadings] = useState(false);
    const [likeiconcolor,setlikeiconcolor]=useState('#E8E4E4')
    const [favorite, setfavorite] = useState([])
    const [book, setbook] = useState({})


    useEffect(() => {
        getBookdeltais()
        getFavorite(nova_userCredentials.apikey);

    }, [])

    const getBookdeltais = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(bookdetails + selecteditem.contentId, requestOptions)
            .then(response => response.json())
            .then(result => {
                setbook(result.data)
                setdescription(result.data.description)
            })
            .catch(error => console.log('error', error));
    }
    const getFavorite = async (apikey) => {
        let axios = require('axios');
        let config = {
            method: 'get',
            url: favoriteURL,
            headers: {
                'apikey': apikey
            }
        };
        axios(config)
            .then((response) => {
                setfavorite(response.data.data)
                var data=response.data.data;
                if(response.data.data.length > 0){
                    data.find((element) =>{ 
                       if(element.eisbn == selecteditem.eisbn){
                        setlikeiconcolor("#DE0414")
                    }else{
                        setlikeiconcolor("#E8E4E4")

                       }
                    })
                }

            })
            .catch(function (error) {
               
            });
    }

    const goTopdf = () => {
        history.push('/pdf')
    }

    const goToepub = () => {
        history.push('/reader')
    }
   
    const addOrRemoveLibrary = (id) => {
        setloadings(true);
        var myHeaders = new Headers();
        myHeaders.append("apikey", nova_userCredentials.apikey);
        var formdata = new FormData();
        formdata.append("contentId", selecteditem.contentId);
        formdata.append("library", id);
        formdata.append("path", bookpath + selecteditem.eisbn);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(saveandremovefav, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(id==1){
                message.success('Book added to your favorite list');
                setlikeiconcolor("#DE0414")
                setloadings(false);
                }else{
                    message.success('Book Removed from your favorite list');
                    setlikeiconcolor("#E8E4E4")
                    setloadings(false);

                }
            })
            .catch(error => {
                setloadings(false);
            });
    }
    const ReadButton=(item)=>{
        let locaion =JSON.parse (localStorage.getItem(item.title));
        console.log("last locatin",locaion)
        let buttontext="Start Reading";
        if(locaion){
            buttontext="Continue Reading"
         }
        if(item.pdfExists && item.epubExists ){
            return(
                <div >
                <Button onClick={()=>{goToepub(item)}} type="primary" icon={<ReadOutlined />} size="small">{buttontext}</Button> 
                <Popover content={content(item)} title="Select Reader" trigger="click">
                 <FilterOutlined style={{marginLeft:'15px',fontSize:'15px',marginTop:'10px',color: '#33AFFF'}} />
              </Popover>
              {loadings?<LoadingOutlined style={{marginLeft:'15px',fontSize:'15px',marginTop:'10px',color:"#0E89F0"}} /> :<LikeFilled  onClick={likeiconcolor=="#DE0414" ?()=>addOrRemoveLibrary(0):()=>addOrRemoveLibrary(1)}  style={{marginLeft:'15px',fontSize:'15px',marginTop:'10px',color:likeiconcolor}} />}
            </div>
            )
        } else if(item.epubExists){
            return(
                  <div>
                       <Button onClick={()=>{goToepub(item)}} type="primary" icon={<ReadOutlined />} size="small">{buttontext}</Button> 
                       {loadings?  <LoadingOutlined style={{marginLeft:'15px',fontSize:'15px',marginTop:'10px',color:"#0E89F0"}}/> :<LikeFilled  onClick={likeiconcolor=="#DE0414" ?()=>addOrRemoveLibrary(0):()=>addOrRemoveLibrary(1)}  style={{marginLeft:'15px',fontSize:'15px',marginTop:'10px',color:likeiconcolor}} />}
                       </div>
            )
        }else{
            return(
            <div>
              <Button onClick={()=>{goTopdf(item)}} type="primary" icon={<FilePdfOutlined />} size="small">{buttontext}</Button> 
              {loadings?  <LoadingOutlined style={{marginLeft:'15px',fontSize:'15px',marginTop:'10px',color:"#0E89F0"}} /> :<LikeFilled  onClick={likeiconcolor=="#DE0414" ?()=>addOrRemoveLibrary(0):()=>addOrRemoveLibrary(1)}  style={{marginLeft:'15px',fontSize:'15px',marginTop:'10px',color:likeiconcolor}} />}
             </div>
            )
        }
       }
       const content =(item)=> (
        <div>
          <p onClick={()=>{goToepub(item)}}> <ReadOutlined style={{fontSize:'15px',marginTop:'10px',color:'blue',marginRight:'10px'}} />Epub</p>
          <p onClick={()=>{goTopdf(item)}}> <FilePdfOutlined style={{fontSize:'15px',marginTop:'10px',color:'red',marginRight:'10px'}} />Pdf</p>
        </div>
      );
     
    return (
        <div style={{height:'100vh'}}>
            <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px", backgroundColor: '#ffffff' }}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
                    <div className="descPage">
                        <Header />
                        <Row>
                            <Col className="journalList" xs={24} sm={24} md={4} lg={4} xl={4}>
                                <img src={bookcover + selecteditem.contentId + "/cover.png"} className="descImg" alt="" />
                            </Col>
                            <Col className="journalList" xs={24} sm={24} md={18} lg={18} xl={18}>
                                <div className="descTitle">{selecteditem.title}</div>
                                <div className="descAuthor">{selecteditem.publisherName}</div>
                                <div className="descPub">ISBN:{selecteditem.eisbn}</div>
                               
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="descHead">Description :</div>
                                <div className="description">{description.replace(/<\/?[^>]+(>|$)/g, "")}</div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <div style={{position:"sticky",display:'flex', bottom: 0,width:'100%',height:'50px',backgroundColor:'#fff',alignItems:'center',justifyContent:'center'}}>
                {ReadButton(selecteditem)}
            </div>
         </div>
    );

}

export default Description;