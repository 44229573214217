import { makeObservable, observable,  action,  } from "mobx";
import { Modal } from 'antd';
import { resendMail, getDocAuth, findUpdateorInsert, searchContent, passCodeVal, contactUs, getbook, getJournals, getLibrary, getbookinfo,getJournalinfo } from './APIEndpoints';
import APIProxy from './APIProxy';




export default class DataStore {

  constructor(value) {
    makeObservable(this, {
      highlightToolBox: observable,
      highlightToolBoxPlacement: observable,
      signupForm: observable,
      loginForm: observable,
      forgotForm: observable,
      currentComponent: observable,
      apiProxy: observable,
      nova_userCredentials: observable,
      selectedItem: observable,
      selectedType: observable,
      selectedArticle: observable,
      getBookListArray: observable,
      lastTabKey: observable,
      searchText: observable,
      handleTextSelected: observable,
      getEmailVerification: action,
      checkLogin: action,
      getArticleList: action,
      getJournalList: action,
      getJournalArticleList: action,
      getBookList: action,
      getLibrary: action,
      addToLibrary: action,
      getParameterByName: action,
      passCodeValidationFn: action,
      contactUs: action,

    })
    this.value = value
  }
  selectedItem = '';
  searchText = '';
  handleTextSelected = '';
  selectedArticle = '';
  lastTabKey = '1';
  selectedType = '';
  getBookListArray = [];
  highlightToolBox = false;
  highlightToolBoxPlacement = 'bottom';
  signupForm = false;
  loginForm = true;
  forgotForm = false;
  currentComponent = 'Login';
  nova_userCredentials = JSON.parse(localStorage.getItem('nova_userCredentials'));
  apiProxy = new APIProxy();

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  addToLibrary = async (id) => {
    let args = { "tbl": "Userlibrary", "find": { "uid": this.nova_userCredentials._id, "bid": id }, "update": { "view": 1, "bid": id, "uid": this.nova_userCredentials._id, "_r": ["5af956974b4bb40a34648f8e"], "_w": ["5af956974b4bb40a34648f8e"] } }
    let url = findUpdateorInsert + encodeURIComponent(JSON.stringify(args))
    let addtolibrary = await this.apiProxy.axiosPost(url);
    return addtolibrary;
  }
  removeFromLibrary = async (id) => {
    let args = { "tbl": "Userlibrary", "find": { "uid": this.nova_userCredentials._id, "bid": id }, "update": { "view": 0, "_r": ["5af956974b4bb40a34648f8e"], "_w": ["5af956974b4bb40a34648f8e"] } }
    let url = findUpdateorInsert + encodeURIComponent(JSON.stringify(args))
    let removeFromlibrary = await this.apiProxy.axiosPost(url);
    return removeFromlibrary;
  }
  passCodeValidationFn = async (email, eISBN, passcode) => {
    let args = { "email": email, "eISBN": eISBN, "passcode": passcode, "deviceName": "chrome", "deviceId": "chromeapp" }
    // let url = passCodeVal + encodeURIComponent(JSON.stringify(args))
    let result = await this.apiProxy.axiosPostAgs(passCodeVal, args);
    return result;
  }

  getLibrary = async () => {
    let args = { "tbl": "Userlibrary", "length": 100, "find": { "uid": this.nova_userCredentials._id, "view": 1 }, "sort": {} }
    let url = getDocAuth + encodeURIComponent(JSON.stringify(args))
    let getUserLibrary = await this.apiProxy.axiosPost(url);
    let getLibraryIds = [];
    let libraryList = [];
    let bits = '';
    for (let i = 0; i < getUserLibrary.data.data.length; i++) {
      bits = getUserLibrary.data.data[i].bid;
      getLibraryIds.push(bits);
    }

    let argsA = { "tbl": "Issue", "length": 50, "find": { "_id": { "$in": getLibraryIds }, "live": true }, "filter": ["Type", "abstract", "author", "coverImageURL", "eISBN", "epub_exists", "id", "knowledge", "live", "locationURL", "openAccess", "openBookId", "paublisherName", "paublisherYear", "pdfURL", "pdf_exists", "production", "pubid", "publisherUrl", "size", "stage", "subject", "time_c", "title", "uid", "currency", "price", "payment_status", "jouid"], "sort": { "time_c": -1 } }
    let urlA = getDocAuth + encodeURIComponent(JSON.stringify(argsA))
    let setUserLibraryBooks = await this.apiProxy.axiosPost(urlA);
    libraryList.push(setUserLibraryBooks.data.data);
    let argsB = { "tbl": "Article", "length": 1000, "find": { "_id": { "$in": getLibraryIds }, "live": true }, "sort": { "time_c": -1 }, "filter": ["ArticleURL", "CoverURL", "EpubURL", "JournalTitle", "PublicationDate", "PublicationYear", "Volume", "XmlURL", "articleSize", "articleTitle", "articleType", "doi", "epubaudit", "jouid", "live", "pid", "pubDate", "pubid", "pdfURL", "pdf_exists", "review", "time_c", "time_u"] }
    let urlB = getDocAuth + encodeURIComponent(JSON.stringify(argsB))
    let setUserLibraryJournals = await this.apiProxy.axiosPost(urlB);
    libraryList.push(setUserLibraryJournals.data.data);
    return libraryList;
  }
  getNewLibrary = async () => {
    let myHeaders = new Headers();
    myHeaders.append("apikey", this.nova_userCredentials.apikey);
    let requestOptions = {
      method: 'GET',
      headers: myHeaders,

      redirect: 'follow'
    };
    let data = await fetch(getLibrary, requestOptions)
    let response = await data.json();
    return response;
  }
  getArticleSearch = async (searchVal) => {
    let url = searchContent + 'q=' + searchVal + '&start=0';
    let getsearchList = await this.apiProxy.axiosPost(url);
    let getsearchIds = [];
    let bits = '';
    if (getsearchList.data.data !== undefined) {
      for (let i = 0; i < getsearchList.data.data.length; i++) {
        bits = getsearchList.data.data[i].id;
        getsearchIds.push(bits);
      }
    }
    let argsA = { "tbl": "Article", "length": 1000, "find": { "_id": { "$in": getsearchIds }, "live": true }, "sort": { "time_c": -1 }, "filter": ["ArticleURL", "CoverURL", "EpubURL", "JournalTitle", "PublicationDate", "PublicationYear", "Volume", "XmlURL", "articleSize", "articleTitle", "articleType", "doi", "epubaudit", "jouid", "live", "pid", "pubDate", "pubid", "pdfURL", "pdf_exists", "review", "time_c", "time_u"] }
    let urlA = getDocAuth + encodeURIComponent(JSON.stringify(argsA))
    let searchList = await this.apiProxy.axiosPost(urlA);
    return searchList;
  }
  getJournalSearch = async (searchVal) => {
    let args = { st: { "bool": { "must": [{ "match_phrase": { "journal": searchVal } }, { "match_phrase": { "type": "Article" } }] } } };
    let url = searchContent + encodeURIComponent(JSON.stringify(args));
    let getsearchList = await this.apiProxy.axiosPost(url);
    let getsearchIds = [];
    let bits = '';
    for (let i = 0; i < getsearchList.data.data.length; i++) {
      bits = getsearchList.data.data[i].id;
      getsearchIds.push(bits);
    }
    let argsA = { "tbl": "Journal", "start": 0, "length": 30, "find": { "live": true, "_id": { "$in": getsearchIds }, "type": "JOURNAL" }, "sort": { "name": 1 }, "filter": ["displayName", "name", "live", "publisherName"] }
    let urlA = getDocAuth + encodeURIComponent(JSON.stringify(argsA))
    let searchList = await this.apiProxy.axiosPost(urlA);
    return searchList;
  }
  getBookSearch = async (searchVal) => {
    let args = { "tbl": "Issue", "length": 1000, "find": { "title": { "$regex": searchVal, "$options": "i" }, "live": true, "_t": 1, "openAccess": { "$in": [true, 2, false] } }, "sort": { "time_c": -1 }, "filter": ["Type", "abstract", "author", "coverImageURL", "eISBN", "epub_exists", "id", "knowledge", "live", "locationURL", "openAccess", "openBookId", "paublisherName", "paublisherYear", "pdfURL", "pdf_exists", "production", "pubid", "publisherUrl", "size", "stage", "subject", "time_c", "title", "uid", "currency", "price", "payment_status", "jouid"] }
    let url = getDocAuth + encodeURIComponent(JSON.stringify(args))
    let setBookList = await this.apiProxy.axiosPost(url);
    return setBookList;
  }
  getArticleList = async () => {
    let args = { "tbl": "Article", "length": 1000, "find": { "live": true }, "sort": {}, "filter": ["ArticleURL", "CoverURL", "EpubURL", "JournalTitle", "PublicationDate", "PublicationYear", "Volume", "XmlURL", "articleSize", "articleTitle", "articleType", "doi", "epubaudit", "jouid", "live", "pid", "pubDate", "pubid", "pdfURL", "pdf_exists", "time_c", "time_u"] };
    let url = getDocAuth + encodeURIComponent(JSON.stringify(args))
    let setArticleList = await this.apiProxy.axiosPost(url);
    return setArticleList;
  }

  getJournalArticleList = async (id) => {
    let args = { "tbl": "Article", "length": 1000, "find": { "jouid": id, "live": true }, "sort": {}, "filter": ["ArticleURL", "CoverURL", "EpubURL", "JournalTitle", "PublicationDate", "PublicationYear", "Volume", "XmlURL", "articleSize", "articleTitle", "articleType", "doi", "epubaudit", "jouid", "live", "pid", "pubDate", "pubid", "review", "pdfURL", "pdf_exists", "time_c", "time_u"] }
    let url = getDocAuth + encodeURIComponent(JSON.stringify(args))
    let setArticleList = await this.apiProxy.axiosPost(url);
    return setArticleList;
  }
  getJournalList = async (start, length) => {
    let args = { "tbl": "Journal", "start": start, "length": length, "find": { "live": true, "type": "JOURNAL" }, "sort": { "name": 1 }, "filter": ["displayName", "name", "live", "publisherName"] };
    let url = getDocAuth + encodeURIComponent(JSON.stringify(args))
    let setJournalList = await this.apiProxy.axiosPost(url);
    return setJournalList;
  }
  getBookList = async (start, length) => {
    let args = { "tbl": "Journal", "length": length, "start": start, "find": { "ptype": 2, "live": true }, "filter": ["Type", "abstract", "author", "coverImageURL", "eISBN", "epub_exists", "id", "knowledge", "live", "locationURL", "openAccess", "openBookId", "paublisherName", "paublisherYear", "pdfURL", "pdf_exists", "production", "pubid", "publisherUrl", "size", "stage", "subject", "time_c", "title", "uid", "currency", "price", "payment_status", "jouid"], "sort": { "time_c": -1 } };
    let url = getDocAuth + encodeURIComponent(JSON.stringify(args))
    let setBookList = await this.apiProxy.axiosPost(url);
    console.log("booklist", setBookList)
    this.getBookListArray.concat(setBookList.data.data);
    return setBookList;
  }
  newGetBookList = async (start) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    let data = await fetch(getbook + start, requestOptions);
    let response = await data.json();
    return response;
  }
  getBookInfo = (id) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch(getbookinfo + id, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }
  newGetJournalList = async (start) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    let data = await fetch(getJournals + start, requestOptions);
    let response = await data.json();
    return response;
  }
  getJournalInfo = async (id) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
   let data = await fetch(getJournalinfo+id+"?start=0", requestOptions)
    let response = await data.json();
    return response;
  }

  getEmailVerification = async (usermail) => {
    var formdata = new FormData();
    formdata.append("email", usermail);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    let data = await fetch(resendMail, requestOptions);
    let result = await data.json();
    return result
  }
  checkLogin = async (result) => {

    if (result.cred === 0) {
      Modal.error({
        title: 'Login failed',
        content: 'Incorrect password. Please check given credentials.',
      });
    }
    else if (result.cred === 2) {
      Modal.warning({
        title: 'Invalid Email-ID',
        content: 'Your email address is not registered.',
      });
    }
    else if (result.cred === 3) {
      Modal.warning({
        title: 'Login Failed',
        content: 'Incorrect email or password. Please try again.',
      });
    }
    else if (result.cred === 1) {
      let user_details = {
        apikey: result.apikey,

        usermail: result.email,
        email: result.username,
        emailVerified: result.emailVerified || result.emailverified,
        displayName: result.displayName,
        _id: result._id,
        // city: result.city,
        // country: result.country,
        // university: result.university,
        // onboard: result.onboard,
        guser: 0
      };
      if (user_details['emailVerified'] === 0 || user_details['emailVerified'] === undefined) {
        let homePage = await this.getEmailVerification(user_details.email);
        console.log("finalresponse", homePage)
        if (homePage.status === 202) {
          {
            Modal.info({
              title: 'Verification',
              content: 'A verification link has been sent to your email address, please click on the link to activate your account.',
            });
          }
        }
      }
      else {
        // alert(window.location.host+'/home');
        // window.location.href = window.location.host+'/home';
        this.nova_userCredentials = user_details;
        localStorage.setItem('nova_userCredentials', JSON.stringify(user_details));
        return true;
      }
    }
    else {
      Modal.warning({
        title: 'Login Failed',
        content: 'Oops error, Please try again',
      });
    };
  }

  //Contact Us 
  contactUs = async (mailfrom, subject, message) => {
    let respon = "";
    let headers = new Headers();
    headers.append("Cookie", "apikey=" + this.nova_userCredentials.apikey + ";" + "displayName=" + this.nova_userCredentials.displayName + ";" + "email=" + this.nova_userCredentials.usermail + ";" + "id=" + this.nova_userCredentials._id);
    
    let formdata = new FormData();
    formdata.append("emailMessage", message);
    formdata.append("emailFrom", mailfrom);
    formdata.append("emailSubject", subject);
    let requestOptions = {
      method: 'POST',
      body: formdata,
      headers: headers,
      redirect: 'follow'
    };
    await fetch(contactUs, requestOptions)
      .then(response => response.json())
      .then(result => respon = result)
      .catch(error => respon = error);
    return respon;

  }


}




// export const dataStore = new DataStore();
