import React, {useEffect,useState } from 'react';
import ListCard from '../../component/dashboard/colllist_Card';
import Header from '../../component/dashboard/header';
import { Row, Col,Breadcrumb,message  } from 'antd';
import {LoadingOutlined,ArrowLeftOutlined} from '@ant-design/icons';

import Space from '../../component/dashboard/space';
import { useSelector,useDispatch } from 'react-redux';
import{useHistory}from 'react-router-dom'
import {catalog} from '../../api/api'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {setSubject,setNewBooks,setBestRatingBooks,setOpenAccessBooks,setNewJournals,setBestRatingJournals} from '../../redux/actions/dashboardActions';
const Allcollection = () => {
  const history=useHistory();
  const [isLoading,setIsLoading]=useState(false)
 
  const [data,setdata]=useState([])
  const [emptyBooks,setEmptyBooks]=useState([{},{},{},{},{},{},{},{}])

  useEffect(() => {
    window.scrollTo(0, 0)
    const nova_userCredentials = JSON.parse(localStorage.getItem('nova_userCredentials'));
    if (nova_userCredentials != null) {
        if (nova_userCredentials.emailVerified != 1) {
            history.push('./login');
        } else {
           fetchDashboardData()            
        }
    }
    else {
        history.push('./login');
    }
   
  }, [])
 
  const fetchDashboardData = async () => {
    setIsLoading(true)
    let axios = require('axios');
    let config = {
      method: 'get',
      url: catalog,
      
    };
     axios(config)
    .then((response)=>{
        setdata(response.data.compendiums)

      setIsLoading(false);
    })
    .catch(function (error) { console.log(error); });
   }
  const goToList = (titel) => {
    history.push('/list')
  }
  if(isLoading){
    return(
      <div className="dashboard">
               <Header titel={"All Collection"} />
               <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">{"All Collection"}</Breadcrumb.Item>
             </Breadcrumb>
             <Row className="listcontiner">
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
            <Row>
                {emptyBooks.map(item =>(
                    <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                        <div  className="journalCover" style={{  backgroundColor:"#EEFBF9" }}>
                         </div>
                    </Col>
                ))}
            </Row>
           </Col>
        </Row>
     
     
     </div>
    )
  }
 
  return (
    <div className="dashboard">
               <Header titel={"All Collection"} />
               <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">{"All Collection"}</Breadcrumb.Item>
             </Breadcrumb>
    {data.map((item)=>
    <>
   <Space hig="1px" color="#ECF0EF"/>

    <ListCard cardtitel={item.name} data={item.contents} titel={item.compendiumFuzzyId} isBook={true} access={"paid"} isLoading={isLoading} />
    <Space hig="1px" color="#ECF0EF"/>
    </>
    )}  
     
     
     </div>
  );
}

export default Allcollection;
