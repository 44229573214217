import React, {useEffect,useState } from 'react';
import{useHistory}from 'react-router-dom'
import {catalog} from '../../api/api'
import {ArrowRightOutlined} from '@ant-design/icons';

const Collections = () => {
  const history=useHistory();
  const [isLoading,setIsLoading]=useState(false)
  const [data,setdata]=useState([])
 
  useEffect(() => {
    window.scrollTo(0, 0)
    const nova_userCredentials = JSON.parse(localStorage.getItem('nova_userCredentials'));
    if (nova_userCredentials != null) {
        if (nova_userCredentials.emailVerified != 1) {
            history.push('./login');
        } else {
           fetchDashboardData()            
        }
    }
    else {
        history.push('./login');
    }
   
  }, [])
 
  const fetchDashboardData = async () => {
    setIsLoading(true)
    let axios = require('axios');
    let config = {
      method: 'get',
      url: catalog,
    
    };
     axios(config)
    .then((response)=>{
      console.log(response.data.compendiums)
      setdata(response.data.compendiums)
      setIsLoading(false);
    })
    .catch(function (error) { console.log(error); });
   }
if(isLoading){
  return(
    <div className="coll-continer">
        <div className='coll-body'>
        {[{},{},{},{},{},{},{},{},{}].map((item)=>
          <div className='coll-card'>

           </div>
        )}  
        </div>
     
     </div>
  )
}
 
  return (
    <div className="coll-continer">
        <div className='coll-body'>
        <div onClick={()=>{history.push("/allcollections")}} className='all-coll-card'>
                <p>All Collections</p>
                <ArrowRightOutlined style={{fontSize:"15px",marginLeft:'10px'}}/>
          </div>
         {data.map((item)=>
          <div onClick={()=>{history.push({pathname:"/bookbycollection/"+item.compendiumFuzzyId,state:{name:item.name}})}} className='coll-card'>
                <p>{item.name}</p>
                <ArrowRightOutlined style={{fontSize:"15px",marginLeft:'10px'}}/>
          </div>
         )} 
        </div>
     
     </div>
  );
}

export default Collections;
