import React, { useState } from "react";
import UserImg from '../../asset/images/user.png'
import { Button, Modal } from 'antd';
import { SettingOutlined, DesktopOutlined, LoadingOutlined,SafetyCertificateOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useIndexedDB } from 'react-indexed-db';
import { useDispatch } from 'react-redux';
import { setreset } from '../../redux/actions/dashboardActions';
import { appversion, checkdevicepair,devicepairupdate } from '../../api/api';
import Rate from './ratecard';

import './styles.css';

const UserCard = (props) => {
  const history = useHistory();
  const { clear } = useIndexedDB('epub');
  const dispatch = useDispatch();
  const nova_userCredentials = JSON.parse(localStorage.getItem('nova_userCredentials'));
  const [visible, setVisible] = useState(false);
  const [ischecking, setischecking] = useState(false);
  const [devicedetails, setdevicedetails] = useState({});
  const [details, setdetails] = useState({});
  const [deny, setdeny] = useState(false);
  const [allow, setallow] = useState(false);
  const [rateUs, setRateUs] = useState(false);
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);

  };

  const handleCancel = () => {
    setVisible(false);

  };

  const getdevicepair = () => {
    setischecking(true)
    var myHeaders = new Headers();
    myHeaders.append("apikey", nova_userCredentials.apikey);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(checkdevicepair + nova_userCredentials.fuzzyId, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setischecking(false)
        if (result.r == 1) {
          props.hidepop();
          let deviceprops = JSON.parse(result.devicePair.props);
          setdetails(result)
          setdevicedetails(deviceprops)
          showModal()
        } else if (result.r == 0) {
          setischecking(false)
          props.hidepop();
          Modal.error({
            title: 'Device Pair',
            content: "We don't see any device pairing requests from the website.",
          });
        } else {
          setischecking(false)
          props.hidepop();
          Modal.error({
            title: 'Device Pair',
            content: 'Erro...',
          });
        }
      })
      .catch(error => {
        setischecking(false)
        console.log('error', error)
      });
  }

  const updatedevicepair = (type) => {
    if(type==0){
      setdeny(true)
    }else if(type==1){
      setallow(true)
    }
    var myHeaders = new Headers();
    myHeaders.append("apikey", nova_userCredentials.apikey);
    var formdata = new FormData();
    formdata.append("type", type);
      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
      fetch(devicepairupdate+details.devicePair.fuzzyId+"/update", requestOptions)
      .then(response => response.json())
      .then(result =>{
        if(result.r==1 && type==1){
          setVisible(false)
          setallow(false)
          setdeny(false)
          
        }else if(result.r==1 && type==0){
          setVisible(false)
          setallow(false)
          setdeny(false)
          
        }else{
          setVisible(false)
          setallow(false)
          setdeny(false)
           Modal.error({
            title: 'Device Pair',
            content: 'Error...',
          });
        }
         })
      .catch(error =>{
        setVisible(false)
          setallow(false)
          setdeny(false)
           Modal.error({
            title: 'Device Pair',
            content: 'Error...',
          });
         console.log('error', error)});
  }

  const logout = () => {
    localStorage.removeItem('nova_userCredentials')    
    history.replace('./login');
    dispatch(setreset())
    // clear().then(() => {
    //   history.replace('./login');
    //   dispatch(setreset())
    // });
  }


  return (
    <div className="userPopup">
      <div className="userImg"><img src={UserImg} alt="userimage" /></div>
      <div className="userName">{nova_userCredentials.displayName}</div>
      <div className="userEmail">{nova_userCredentials.email}</div>
      <div className="menu-continer">
        <p style={{fontFamily:'var(--font)' }} onClick={() => history.push('/setting')}> <SettingOutlined className="icon" style={{ marginTop: '15px' }} />Setting</p>
        <p style={{fontFamily:'var(--font)' }} onClick={() =>{props.hidepop();setRateUs(true)}}> <SafetyCertificateOutlined className="icon" style={{ marginTop: '15px' }} />Rate Us</p>
        {ischecking ? <p style={{fontFamily:'var(--font)' }} onClick={getdevicepair} > <LoadingOutlined className="icon" style={{ marginTop: '15px' }} />Checking...</p> : <p style={{fontFamily:'var(--font)' }} onClick={getdevicepair} > <DesktopOutlined className="icon" style={{ marginTop: '15px' }} />Device Pair</p>}

      </div>
      <div className="logout"><Button className="loginBtn" onClick={logout} type="primary"><span className="btntext">Logout</span></Button></div>
      <div style={{ marginTop: '10px', letterSpacing: '1px', color: '#979595',fontFamily:'var(--font)' }}>app version:<span style={{ color: '#B4B4B4', marginLeft: '5px',fontFamily:'var(--font)' }}>{appversion}</span> </div>
      <br />
      <Modal
        visible={visible}
        title="Device Pair"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button onClick={()=>updatedevicepair(0)} key="back" danger={true} loading={deny}>
            DENY
          </Button>,
          <Button onClick={()=>updatedevicepair(1)} key="submit" type="primary" loading={allow}>
            APPROVE
          </Button>,]}>
        <p>Hi, You are trying to access the website from IP:{devicedetails.ip}, and City:{devicedetails.city} using the Device Pairing feature. If that is you, please click Approve, otherwise click Deny.</p>
      </Modal>
      <Modal
        visible={rateUs}
        title="Rate Us"
        footer={null}
        onCancel={()=>setRateUs(false)}
        >
         <Rate/>
      </Modal>
    </div>);
}
export default UserCard;