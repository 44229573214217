import React, {useState,useEffect} from "react";
import { Row, Col,Breadcrumb,message  } from 'antd';
import {bookcover,subject} from '../../api/api'
import { useHistory,useLocation} from 'react-router-dom'
import Header from '../../component/dashboard/header';
import listbook from '../../api/images/emptybook.png'
import {LoadingOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import './styles.css';

const Booksbysubject=(props)=> {
    const history = useHistory();
    const location = useLocation();
    const name = location.state.name
    const [books,setBooks]=useState([])
    const [emptyBooks,setEmptyBooks]=useState([{},{},{},{},{},{},{},{}])
    const subjectid=props.match.params.subject
    const [isLoading,setIsLoading]=useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)

        setIsLoading(true)
         getBooksList()
     }, [])
   
   
  const  getBooksList = async() => {
         var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        
        fetch(subject+subjectid, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setBooks(result.data)
            setIsLoading(false)
          })
          .catch(error =>{
            setIsLoading(false)
            message.error('Sorry, something went wrong.');

          } );
    }
    const trancateTitle= (title)=> {
      try{
          var length = 20;
          if (title.length > length) {
             title = title.substring(0, length)+'...';
          }
          return title;
      }catch{
          return title;

      }
      
  }
   
   
  
  if(isLoading){
    return (
        <div className="continer">
                                   <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

        <Header titel={name} />
        <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">{name}</Breadcrumb.Item>
             </Breadcrumb>
        <Row className="listcontiner">
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
            <Row>
                {emptyBooks.map(item =>(
                    <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                        <div  className="journalCover" style={{  backgroundColor:"#EEFBF9" }}>
                         </div>
                    </Col>
                ))}
            </Row>
           </Col>
        </Row>
        </Col>
        </div>
    );
  }

   
        return (
            <div className="continer">
                                       <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

            <Header titel={name} />
            <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">{name}</Breadcrumb.Item>
             </Breadcrumb>
          {books.length >0 ?  <Row className="listcontiner">
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

                 <Row>
                    {books.map(item =>(
                        <Col className="journalList"  xs={12} sm={8} md={6} lg={4} xl={4}>
                            <div onClick={()=>{history.push({pathname:'/book/isbn/'+item.isbn,state:{contentid:item.contentId}})}}  className="journalCover" style={{ backgroundImage: `url(${bookcover+item.fuzzyId+"/"+item.cover})`, backgroundColor:"#EEFBF9" }}>
                              
                            </div>
                            <p className='ctitel'>{trancateTitle(item.name)}</p>

                        </Col>
                    ))}
                </Row>
               
            </Col>
            </Row>:
             <div className="nodata">
                 <img src={listbook} width={"100px"}/>
                 No book found under the {subjectid}  category,Sorry!
            </div>
            }
            </Col>
            </div>
        );
    }


export default Booksbysubject;