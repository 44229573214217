import React, { useState, useEffect } from 'react';
import Header from '../../component/dashboard/header';
import {Row, Col,} from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { sethometab } from '../../redux/actions/dashboardActions';
import { HomeOutlined,UnorderedListOutlined,ProfileOutlined} from '@ant-design/icons';
import Dash from './dashboard';
import Lib from '../library/library';
import Coll from './collectios';
import './dashboardStyles.css';
import 'antd-mobile/dist/antd-mobile.css';



const Home = () => {
    const history = useHistory();
    const [loading, setloading] = useState(true);
    const dispatch = useDispatch();
    const state = useSelector((state) => state.App);
    const configFile = process.env.REACT_APP_ENV

    useEffect(() => {
        const nova_userCredentials = JSON.parse(localStorage.getItem('nova_userCredentials'));
        if (nova_userCredentials != null) {
            if (nova_userCredentials.emailVerified != 1) {
                history.push('./login');
                setloading(false)
            }
        }
        else {
            history.push('./login');
            setloading(false)
        }
    }, [])

const sethome = () => dispatch(sethometab("home"))
const setlib = () => dispatch(sethometab("lib"))
const setdow = () => dispatch(sethometab("coll"))
    
return (
        <div>
        <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
                 <Header/> 
                {state.hometab=="home"?
                        <Dash/>
                        : null}
                         {state.hometab=="lib"?
                        <Lib/>
                        : null}
                          {state.hometab=="coll"?
                        <Coll/>
                        : null}
               </Col>
        </Row>
       <div className="tabbarrcontiner">
               <div className="tabbarr" >
                    <div className="tabbar_itemm" onClick={() => sethome()} style={{  color: state.hometab=="home" ? "#ffffff" : "#000" }}><HomeOutlined style={{fontSize:"15px"}}/>Home </div>
                    <div className="tabbar_itemm" onClick={() => setlib()} style={{  color: state.hometab=="lib" ? "#ffffff" : "#000" }}><ProfileOutlined style={{fontSize:"15px"}} />Library</div>
                    {configFile=="lpp"?<div className="tabbar_itemm" onClick={() => setdow()} style={{ color: state.hometab=="coll" ? "#ffffff" : "#000" }}><UnorderedListOutlined  style={{fontSize:"15px"}} />Collections</div>:null}
                </div>
                </div>
      </div>)
}

export default Home;