import React, { useState, useEffect } from "react";
import { Col, Row, Button, message, Collapse, Modal, Input, Breadcrumb, Tabs, Typography, Result,Space} from "antd";
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../component/dashboard/header'
import { useHistory, useLocation } from 'react-router-dom'
import { bookcover, bookpath, saveandremovefav, bookdetails, favoriteURL, subscriptionURL, passcodevalidation, serverurl } from '../../api/api';
import { RWebShare } from "react-web-share";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ReadOutlined, ArrowLeftOutlined, FilePdfOutlined, CopyOutlined, BarcodeOutlined, HeartFilled, LoadingOutlined, CalendarOutlined, DownloadOutlined, ShareAltOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import './styles.css';
const { TabPane } = Tabs;
const { Title } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;

const NewDescription = (props) => {
    const location = useLocation();
    const contentid = location.state.contentid;
    const fuzzyid = location.state.fuzzyid
    const isbn = props.match.params.isbn
    const history = useHistory();
    const dispatch = useDispatch();
    const nova_userCredentials = JSON.parse(localStorage.getItem('nova_userCredentials'));
    const [description, setdescription] = useState('');
    const [loadings, setloadings] = useState(false);
    const [loading, setloading] = useState(false);
    const [subloading, setsubloading] = useState(false);
    const [likeiconcolor, setlikeiconcolor] = useState('#E8E4E4')
    const [isfavorite, setisfavorite] = useState(false)
    const [book, setbook] = useState({})
    const [isfree, setisfree] = useState(77)
    const [bookaccess, setbookaccess] = useState("77")
    const [issub, setissub] = useState(false)
    const [visible, setVisible] = useState(false);
    const [readervisible, setreadervisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const [password, setpassword] = useState("")
    const [ispasswordvalid, setispasswordvalid] = useState(false)
    const [isbtnloading, setsbtnloading] = useState(false)
    const [isuserclickbuy, setuserclickbuy] = useState(false)
    const [authors, setauthors] = useState([])
    const [toc, settoc] = useState()
    const [error, seterror] = useState(false)
    const [format, setformat] = useState('')
    const [pdf, setpdf] = useState(false)
    const [epub, setepub] = useState(false)
    const [rating,setrating]=useState('')
    const [feedback,setfeedback]=useState(' ')
    const [pricing,setpricing]=useState([])
    const [seletedformet,setseletedformet]=useState()

    useEffect(async () => {
        window.scrollTo(0, 0)
        await Promise.all([
            getBookdeltais(),
            getSub(nova_userCredentials.apikey),
            getFavorite(nova_userCredentials.apikey),

        ])

    }, [])

    const refreash = async () => {
        setbook({})
        setdescription('')
        setbookaccess("77")
        setisfavorite(false)
        setissub(false)
        await Promise.all([
            getBookdeltais(),
            getFavorite(nova_userCredentials.apikey),
            getSub(nova_userCredentials.apikey),
        ])
    }

    const getBookdeltais = () => {
        setloading(true)
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(bookdetails + isbn, requestOptions)
            .then(response => response.json())
            .then(result => {
                let getauthors = !result.authorlist? "no athore": result.authorlist.map(item => item.name)
                setbook(result.content)
                setdescription(result.description)
                setbookaccess(result.content.access)

                setpricing(result.contentPrice)
                setauthors(getauthors)
                settoc(result.toc)
                setloading(false)

            })
            .catch(error => {
                console.log(error)

                seterror(true)
                setloading(false)

            });
    }
    const getFavorite = async (apikey) => {
        let axios = require('axios');
        let config = {
            method: 'get',
            url: favoriteURL,
            headers: {
                'apikey': apikey
            }
        };
        axios(config)
            .then((response) => {

                if (response.data.data.length > 0) {
                    let isValuefound = response.data.data.filter(obj => obj.eisbn === isbn);
                    if (isValuefound.length > 0) {
                        setisfavorite(true)
                    } else {
                        setisfavorite(false)
                    }


                } else {
                    setisfavorite(false)

                }

            })
            .catch(function (error) {

            });
    }

    const goTopdf = () => {
        history.push({ pathname: '/read/book/pdf/' + book.elasticId, state: { contentid: contentid, fuzzyid: book.fuzzyId, isbn: isbn, name: book.name, type: "book", isonline: 'yes', cover: book.cover } })
    }

    const goToepub = () => {
        history.push({ pathname: '/read/book/epub/' + book.elasticId, state: { contentid: contentid, isbn: isbn, name: book.name, type: "book", fuzzyid: book.fuzzyId, cover: book.cover } })
    }

    const addOrRemoveLibrary = (id) => {
        setloadings(true);
        var myHeaders = new Headers();
        myHeaders.append("apikey", nova_userCredentials.apikey);
        var formdata = new FormData();
        formdata.append("contentId", contentid);
        formdata.append("library", id);
        formdata.append("path", bookpath + isbn);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(saveandremovefav, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (id == 1) {
                    message.success('Book added to your favorite list');
                    setisfavorite(true)
                    setloadings(false);
                } else {
                    message.success('Book Removed from your favorite list');
                    setisfavorite(false)
                    setloadings(false);

                }
            })
            .catch(error => {
                setisfavorite(false)
                setloadings(false);
                message.error('Sorry, something went wrong.');


            });
    }

    const content = (item) => (
        <div>
            <p onClick={() => { goToepub(item) }}> <ReadOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'blue', marginRight: '10px' }} />Epub</p>
            <p onClick={() => { goTopdf(item) }}> <FilePdfOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'red', marginRight: '10px' }} />Pdf</p>
        </div>
    );
    const getSub = async (apikey) => {
        setsubloading(true)
        let axios = require('axios');
        let config = {
            method: 'get',
            url: subscriptionURL,
            headers: {
                'apikey': apikey
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.data.length > 0) {
                    let isValuefound = response.data.data.filter(obj => obj.eisbn === isbn);
                    if (isValuefound.length > 0) {
                        console.log(isValuefound)
                        setformat(isValuefound[0].format)
                        setsubloading(false)
                        setissub(true)
                    } else {
                        setsubloading(false)
                        setissub(false)
                    }

                } else {
                    setsubloading(false)
                    setissub(false)

                }

            })
            .catch(function (error) {
                console.log(error)
                setsubloading(false)
                setissub(false)
            });
    }

    const showModal = () => {
        setVisible(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        if (password.length > 0) {
            setConfirmLoading(true);
            var myHeaders = new Headers();
            myHeaders.append("apikey", nova_userCredentials.apikey);
            var formdata = new FormData();
            formdata.append("contentId", contentid);
            formdata.append("passcode", password);
            formdata.append("deviceName", "chrome");
            formdata.append("deviceId", "chromeapp");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            fetch(passcodevalidation, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.r == 1) {
                        setConfirmLoading(false);
                        setispasswordvalid(true)
                        setVisible(false)
                        if(seletedformet.format=="EPUB"){
                            goToepub()
                         }
                         if(seletedformet.format=="PDF"){
                          goTopdf()
                        }
                        // if (book.epubExists) {
                        //     history.push({ pathname: '/read/book/epub/' + book.elasticId, state: { contentid: contentid, fuzzyid: fuzzyid, isbn: isbn, name: book.name, type: "book", cover: book.cover } })
                        // } else {
                        //     history.push({ pathname: '/read/book/pdf/' + book.elasticId, state: { contentid: contentid, fuzzyid: fuzzyid, isbn: isbn, name: book.name, type: "book", isonline: 'yes', cover: book.cover } })
                        // }
                    } else {
                        setConfirmLoading(false);

                        message.warning('Enter valid password');
                    }
                })
                .catch(error => {
                    message.warning('Enter valid password');
                    setConfirmLoading(false);
                });


        } else {
            setConfirmLoading(false);
            message.warning('Enter valid password');
        }


    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };
    const handleCancelreader = () => {
        console.log('Clicked cancel button');
        setreadervisible(false);
    };
    const onChange = e => {
        setpassword(e.target.value)
        console.log('Change:', e.target.value);
    };
    const Change = (key) => {
        console.log(key);
      };
    const contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();

        console.log(targetLink.href);
    }
    const GoToreader = (item) => {
        if (bookaccess == 2 && !issub) {
             setseletedformet(item)
             showModal()
           
        } else if (issub || bookaccess == 1) {
           if(item.format=="EPUB"){
              goToepub()
           }
           if(item.format=="PDF"){
            goTopdf()
          }
        }
    }
   
    const  handleChangerate = value => {
        setrating(value)
      };
      const onChangefeed = e => {
        setfeedback(e.target.value)
        console.log('Change:', e.target.value);
      };
      
    if (loading || subloading) {
        return (
            <div className="continer">
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

                    <Header />
                    <Breadcrumb separator=" " style={{ marginTop: '10px', marginLeft: '10px', marginBottom: '5px' }} >
                        <Breadcrumb.Item><ArrowLeftOutlined onClick={() => { history.goBack() }} /></Breadcrumb.Item>
                        <Breadcrumb.Item className="pageheadertitle">Book</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row className="listcontiner" style={{ "padding": "0px", "margin": "0px 0px 0px 0px", padding: '5px', borderRadius: "5px" }}>
                        <div style={{ width: '100%',alignItems:'center',display:'flex',flexDirection:'column' }}>
                        <Skeleton width={280} height={300} />
                        <div style={{height:"10px",width:'100%'}}></div>
                        <Skeleton width={230} height={25}  />
                        <div style={{height:"10px",width:'100%'}}></div>

                          <Skeleton width={300} height={10} count={5} />
                          <div style={{height:"10px",width:'100%'}}></div>
                          <Skeleton width={230} height={25}  />
                          <div style={{height:"5px",width:'100%'}}></div>

                          <Skeleton width={230} height={25}  />


                           
                        </div>

                       
                    </Row>
                </Col>
            </div>
        )
    }

    if (error) {
        return (
            <div className="continer" >
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

                    <Header />
                    <Breadcrumb separator=" " style={{ marginTop: '10px', marginLeft: '10px', marginBottom: '5px' }} >
                        <Breadcrumb.Item><ArrowLeftOutlined onClick={() => { history.goBack() }} /></Breadcrumb.Item>
                        <Breadcrumb.Item className="pageheadertitle">Book</Breadcrumb.Item>
                    </Breadcrumb>
                    <Result
                        status="500"
                        title="500"
                        subTitle="Sorry, something went wrong."
                        extra={<Button onClick={() => { history.goBack() }} type="primary">Back</Button>}
                    />
                </Col>
            </div>
        )
    }


    return (

        <div className="continer">
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

                <Header />
                <Breadcrumb separator=" " style={{ marginTop: '10px', marginLeft: '10px', marginBottom: '5px' }} >
                    <Breadcrumb.Item><ArrowLeftOutlined onClick={() => { history.goBack() }} /></Breadcrumb.Item>
                    <Breadcrumb.Item className="pageheadertitle">Book</Breadcrumb.Item>
                </Breadcrumb>
                <Row className="listcontiner" style={{ "padding": "0px", "margin": "0px 0px 0px 0px", padding: '5px', borderRadius: "5px" }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <img className="dcover" src={bookcover + book.fuzzyId + "/" + book.cover} />
                        </div>
                        <div style={{ display: 'flex', marginBottom: '30px', marginTop: '5px', marginLeft: '10px', marginRight: '10px', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ textAlign: 'center', fontSize: '15px', letterSpacing: '1px', fontFamily: 'var(--font)', color: '#000', fontWeight: 'bold' }}>{book.name}</div>
                            <div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>Author(s):</span>{authors.toString().replaceAll(",", ", ")}</div>
                            <div>{book.publisherName}</div>
                            <div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>ISBN:</span>{book.isbn}</div>
                            <div style={{ width: '50%', height: '40px', marginTop: '10px', fontFamily: 'var(--font)', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', fontSize: '20px' }}>{loadings ? <LoadingOutlined style={{ color: 'red' }} /> : <HeartFilled style={{ color: isfavorite ? "red" : "#273746" }} onClick={() => { if (isfavorite) { addOrRemoveLibrary(0) } else { addOrRemoveLibrary(1) } }} />}
                                <RWebShare
                                    data={{
                                        text: book.name,
                                        url: serverurl + "book/isbn/" + book.eisbn,
                                        title: "Share",
                                    }}
                                    onClick={() => console.log("shared successfully!")}>
                                    <ShareAltOutlined /></RWebShare></div>

                                    {/* <div className="bookinfo">
                                        <div className="info-box">
                                            <p className="infotitel">Paper Back</p>
                                              <CopyOutlined  className="info-icon"/>                                            
                                              <p className="info-food">280 page</p>

                                        </div>
                                        <div className="with1"></div>
                                        <div className="info-box">
                                            <p className="infotitel">Date</p>
                                            <CalendarOutlined  className="info-icon"/>                                            
                                            <p className="info-food">12-02-2016</p>
                                        </div>
                                        <div className="with1"></div>

                                        <div className="info-box">
                                            <p className="infotitel">ISBN</p>
                                            <BarcodeOutlined className="info-icon"/>                                            
                                            <p className="info-food">8764567908</p>
                                        </div>

                                    </div> */}

                            {/* {bookaccess == 0 && !issub || bookaccess == 3 && !issub || bookaccess == 4 && !issub ? <div onClick={() => { window.open(serverurl + "book/isbn/" + book.eisbn); setuserclickbuy(true) }} style={{ width: '50%', height: '40px', backgroundColor: '#ff7600', borderRadius: '5px', marginTop: '10px', fontFamily: 'var(--font)', display: 'flex', alignItems: 'center', color: '#fff', justifyContent: 'center', fontWeight: 'bold', letterSpacing: '1px' }}>Buy Now</div> : !pdf && !epub ? null : <div onClick={goToreader} style={{ width: '50%', height: '40px', backgroundColor: '#ff7600', borderRadius: '5px', marginTop: '10px', fontFamily: 'var(--font)', display: 'flex', alignItems: 'center', color: '#fff', justifyContent: 'center', fontWeight: 'bold', letterSpacing: '1px' }}>Start Reading</div>} */}
                            {bookaccess == 0 && !issub || bookaccess == 3 && !issub || bookaccess == 4 && !issub ? 
                           <>
                           {pricing.map((item)=>{
                            return(
                               <>
                                <div className="reader-card">
                    <div className="reader-card-info">
                            <h3>{item.displayName}</h3>
                            <p><span>ISBN </span>{item.isbn}</p>
                        </div>
                        <div className="icon-continer">
                                                                      
                                <h4>{item.currency+" "+ item.amount}</h4>   
                            
                        </div>
                    </div>
                    </>)})}
                    <div onClick={() => { window.open(serverurl + "book/isbn/" + book.isbn); setuserclickbuy(true) }}  className="reader-card">
                        <div className="reader-btn-buy">
                            <p>BUY NOW</p>
                        </div>
                       
                     </div>
                             </>
                            :pricing.map((item)=>{
                                if(item.format == "EPUB" || item.format == "PDF" ){
                                return(
                            <>
                             <div className="reader-card">
                    <div className="reader-card-info">
                        <h3>{item.displayName}</h3>
                        <p><span>ISBN </span>{item.isbn}</p>
                    </div>
                    <div onClick={()=>GoToreader(item)} className="icon-continer">
                        <div className="icons">
                        <ReadOutlined className="readr-icon"/>                                            
                        <DownloadOutlined  className="readr-icon"/>                                            

                        </div>
                    </div>
                 </div>
                            </>)}}
                            )}

                        </div>
                       
                        <Collapse style={{marginBottom: '30px'}} expandIconPosition={["right"]}  onChange={Change} >
                            <Panel header="About Book" key="1" className="panal">
                            <p style={{ padding: '10px', borderRadius: '5px', fontFamily: 'var(--font)', color: '#273746' }} dangerouslySetInnerHTML={{ __html: description }} />
                            </Panel>
                            <Panel header="Table of Content" key="2" className="panal">
                            <div style={{ padding: '10px', borderRadius: '5px', fontFamily: 'var(--font)', color: '#273746' }} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: toc }} />
                            </Panel>
                            {/* <Panel header="User Review" key="3" className="panal">
                                <div className="comment">
                                 Rating
                                <Rate   onChange={handleChangerate}  value={rating} />
                                User Review
                                <TextArea onChange={onChangefeed} value={feedback} placeholder="Review" showCount maxLength={100} style={{marginTop:'5px'}} rows={3} />
                                <div className="submit-btn">
                                    <p>Submit</p>
                                </div>
                                </div>
                               
                            </Panel> */}
                            
                        </Collapse>
                    </div>
                    <Modal
                        title={<Title style={{ fontFamily: 'var(--font)', letterSpacing: '1px', fontWeight: 'bold', fontSize: '15px' }}>Select Reader</Title>}
                        visible={readervisible}
                        footer={null}
                        onCancel={handleCancelreader}>
                        <div>
                            <p style={{ fontFamily: 'var(--font)', letterSpacing: '1px' }} onClick={() => { goToepub() }}> <ReadOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'blue', marginRight: '10px', fontFamily: 'var(--font)', letterSpacing: '1px' }} />Epub</p>
                            <p style={{ fontFamily: 'var(--font)', letterSpacing: '1px' }} onClick={() => { goTopdf() }}> <FilePdfOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'red', marginRight: '10px', fontFamily: 'var(--font)', letterSpacing: '1px' }} />Pdf</p>
                        </div>
                    </Modal>
                    <Modal
                        title={<Title style={{ fontFamily: 'var(--font)', letterSpacing: '1px', fontWeight: 'bold', fontSize: '15px' }}>Enter Passcode</Title>}
                        visible={visible}
                        onOk={handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}>
                        <Input.Password placeholder="Passcode" value={password} onChange={onChange} />
                    </Modal>
                </Row>
            </Col>
        </div>
    );

}

export default NewDescription;