import React, { useState } from 'react';
import './styles.css';
import {logo} from '../../api/api'
import { Popover,Input, message } from 'antd';
import User from './userCard';
import { UserOutlined,SearchOutlined} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
const { Search } = Input;

const Header = () => {
  const [visible, setvisible] = useState(false)

  const history = useHistory();

  
  const handleVisibleChange = visible => {
    setvisible(visible)
  };
 

  
  

  return (
    <div className="navheader">
      <div className="notification_box">
        <div className="navlogo">
          <img src={logo} alt="" style={{  height: '100%' }} />
        </div>
      </div>
      <div className="menucontiner">
      <div className="profile">
      <div className="searchinput" onClick={() => history.push('/search') }>
                    <div className='searchbox'>
                        <input   type="search"   className="searchboxfiled" placeholder="search..." name="search"  />
                        <SearchOutlined  className='searchicon' />
                    </div>
                </div>
          {/* <SearchOutlined onClick={() => history.push('/search')} className="menuicon"/> */}
          </div>
      <Popover visible={visible}
          trigger="click"
          onVisibleChange={handleVisibleChange} content={<User hidepop={()=>setvisible(false)} />} >
          <div className="profile">
          <UserOutlined className="menuicon"/>
          </div>
        </Popover>
       
      </div>
    </div>
  )
}

export default Header;