import Logo from './images/LPP.png';
export const SERVER_URL = "https://livedplacespublishing.com/";
export const APP_NAME="LPP";
export const LOGO=Logo;
export const FbLoginID=712424803526151;
export const AppleClientId="co.novareader.researchpad"
export const GmailClientId="458582913994-ufc1of4nlhc3vmc9h89rdhupeon86bss.apps.googleusercontent.com"
export const OrcidClientId="https://orcid.org/oauth/authorize?client_id=APP-NE6GOPUF09930F9B&response_type=code&scope=/authenticate&redirect_uri=https://mobile.livedplacespublishing.com/login"
export const URL="https://mobile.livedplacespublishing.com/"
export const AppVersion="0.4.3"
export const RedirectUri="https://livedplacespublishing.com/api/applelogin"
export const Cover="https://livedplacespublishing.com/unsecured/";
