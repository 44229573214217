import React, { useState, useEffect } from "react";
import { Col, Row, Button, message, Popover, Modal, Input } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../component/pageheader/pageHeader';
import { useHistory } from 'react-router-dom'
import { bookcover, bookpath, saveandremovefav, bookdetails, favoriteURL, subscriptionURL, passcodevalidation } from '../../api/api';
import { ReadOutlined, FilePdfOutlined, FilterOutlined, LikeFilled, HeartFilled, LoadingOutlined,LeftOutlined ,HeartOutlined} from '@ant-design/icons';
import "antd/dist/antd.css";
import './styles.css';

const NewDescription = (props) => {
    const contentid = props.match.params.contentid
    const isbn = props.match.params.isbn
    const access = props.match.params.access
    const history = useHistory();
    const dispatch = useDispatch();
    const nova_userCredentials = JSON.parse(localStorage.getItem('nova_userCredentials'));
    const [description, setdescription] = useState('');
    const [loadings, setloadings] = useState(false);
    const [likeiconcolor, setlikeiconcolor] = useState('#E8E4E4')
    const [isfavorite, setisfavorite] = useState(false)
    const [book, setbook] = useState({})
    const [isfree, setisfree] = useState(77)
    const [issub, setissub] = useState(false)
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const [password, setpassword] = useState("")
    const [ispasswordvalid, setispasswordvalid] = useState(false)
    const [isbtnloading, setsbtnloading] = useState(false)

    useEffect( async() => {
        await Promise.all([
        getBookdeltais(),
        getFavorite(nova_userCredentials.apikey),
        getSub(nova_userCredentials.apikey),
        ])

    }, [])

    const getBookdeltais = () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(bookdetails + contentid, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log("Book", result.data)
                setbook(result.data)
                setdescription(result.data.description)
                setisfree(result.data.access)

            })
            .catch(error => console.log('error', error));
    }
    const getFavorite = async (apikey) => {
        let axios = require('axios');
        let config = {
            method: 'get',
            url: favoriteURL,
            headers: {
                'apikey': apikey
            }
        };
        axios(config)
            .then((response) => {
                
                if (response.data.data.length > 0) {
                    let isValuefound = response.data.data.filter(obj=>obj.eisbn===isbn);
                     if(isValuefound.length>0){
                        setisfavorite(true)
                     }else{
                        setisfavorite(false)
                     }
                   
                      
                }else{
                    setisfavorite(false)

                }

            })
            .catch(function (error) {

            });
    }

    const goTopdf = () => {
        history.push('/pdf/' + contentid + "/" + isbn + "/" + book.name + "/" + book.elasticId + "/" + "yes"+"/"+"book")
    }

    const goToepub = () => {
        history.push('/reader/' + contentid + "/" + isbn + "/" + book.name + "/" + book.elasticId + "/" + "yes"+"/"+"book")
    }

    const addOrRemoveLibrary = (id) => {
        setloadings(true);
        var myHeaders = new Headers();
        myHeaders.append("apikey", nova_userCredentials.apikey);
        var formdata = new FormData();
        formdata.append("contentId", contentid);
        formdata.append("library", id);
        formdata.append("path", bookpath + isbn);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(saveandremovefav, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (id == 1) {
                    message.success('Book added to your favorite list');
                    setisfavorite(true)
                    setloadings(false);
                } else {
                    message.success('Book Removed from your favorite list');
                    setisfavorite(false)
                    setloadings(false);

                }
            })
            .catch(error => {
                setisfavorite(false)
            });
    }
    const ReadButton = (item) => {
        let locaion = JSON.parse(localStorage.getItem(item.title));
        let buttontext = "Start Reading";
        if (locaion) {
            buttontext = "Continue Reading"
        }
        if (item.pdfExists && item.epubExists) {
            return (
                <div>
                    <Button onClick={() => { goToepub(item) }} type="primary" icon={<ReadOutlined />} size="small">{buttontext}</Button>
                    <Popover content={content(item)} title="Select Reader" trigger="click">
                        <FilterOutlined style={{ marginLeft: '15px', fontSize: '15px', marginTop: '10px', color: '#33AFFF' }} />
                    </Popover>
                    {loadings ? <LoadingOutlined style={{ marginLeft: '15px', fontSize: '15px', marginTop: '10px', color: "#0E89F0" }} /> : <LikeFilled onClick={likeiconcolor == "#DE0414" ? () => addOrRemoveLibrary(0) : () => addOrRemoveLibrary(1)} style={{ marginLeft: '15px', fontSize: '15px', marginTop: '10px', color: likeiconcolor }} />}
                </div>
            )
        } else if (item.epubExists) {
            return (
                <div>
                    <Button onClick={() => { goToepub(item) }} type="primary" icon={<ReadOutlined />} size="small">{buttontext}</Button>
                    {loadings ? <LoadingOutlined style={{ marginLeft: '15px', fontSize: '15px', marginTop: '10px', color: "#0E89F0" }} /> : <LikeFilled onClick={likeiconcolor == "#DE0414" ? () => addOrRemoveLibrary(0) : () => addOrRemoveLibrary(1)} style={{ marginLeft: '15px', fontSize: '15px', marginTop: '10px', color: likeiconcolor }} />}
                </div>
            )
        } else {
            return (
                <div>
                    <Button onClick={() => { goTopdf(item) }} type="primary" icon={<FilePdfOutlined />} size="small">{buttontext}</Button>
                    {loadings ? <LoadingOutlined style={{ marginLeft: '15px', fontSize: '15px', marginTop: '10px', color: "#0E89F0" }} /> : <LikeFilled onClick={likeiconcolor == "#DE0414" ? () => addOrRemoveLibrary(0) : () => addOrRemoveLibrary(1)} style={{ marginLeft: '15px', fontSize: '15px', marginTop: '10px', color: likeiconcolor }} />}
                </div>
            )
        }
    }
    const content = (item) => (
        <div>
            <p onClick={() => { goToepub(item) }}> <ReadOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'blue', marginRight: '10px' }} />Epub</p>
            <p onClick={() => { goTopdf(item) }}> <FilePdfOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'red', marginRight: '10px' }} />Pdf</p>
        </div>
    );
    const getSub = async (apikey) => {
        setsbtnloading(true)
        let axios = require('axios');
        let config = {
            method: 'get',
            url: subscriptionURL,
            headers: {
                'apikey': apikey
            }
        };
        axios(config)
            .then((response) => {
                console.log(response)
                if (response.data.data.length > 0) {
                    let isValuefound = response.data.data.filter(obj=>obj.eisbn===isbn);
                     if(isValuefound.length>0){
                        setsbtnloading(false)
                        setissub(true)
                     }else{
                        setsbtnloading(false)
                        setissub(false)
                     }

                }else{
                    setsbtnloading(false)
                    setissub(false)
                }

            })
            .catch(function (error) {
                setsbtnloading(false)
                setissub(false)
            });
    }
   
    const showModal = () => {
        setVisible(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        if (password.length > 0) {
            setConfirmLoading(true);
            var myHeaders = new Headers();
            myHeaders.append("apikey", nova_userCredentials.apikey);
            var formdata = new FormData();
            formdata.append("contentId", contentid);
            formdata.append("passcode", password);
            formdata.append("deviceName", "chrome");
            formdata.append("deviceId", "chromeapp");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            fetch(passcodevalidation, requestOptions)
                .then(response => response.json())
                .then(result =>{
                     if(result.r==1){
                        setConfirmLoading(false);
                        setispasswordvalid(true)
                        setVisible(false)
                        if(book.epubExists){
                            history.push('/reader/' + contentid + "/" + isbn + "/" + book.name + "/" + book.elasticId + "/" + "yes"+"/"+"book")

                        }else{
                            history.push('/pdf/' + contentid + "/" + isbn + "/" + book.name + "/" + book.elasticId + "/" + "yes"+"/"+"book")

                        }
                        // setispasswordvalid(true)
                     }else{
                        setConfirmLoading(false);
                       
                        message.warning('Enter valid password');
                     }
                })
                .catch(error => console.log('error', error));

           
        } else {
            setConfirmLoading(false);
            message.warning('Enter valid password');
        }


    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };
    const onChange = e => {
        setpassword(e.target.value)
        console.log('Change:', e.target.value);
    };

    const Buttonrender = () => {
        // if (access == "free" || isfree == 1) {
        //     return (
        //         <div style={{ position: "sticky", display: 'flex', bottom: 0, width: '100%', height: '40px', backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center' }}>
        //             {ReadButton(book)}
        //         </div>
        //     )
        // } else 
        
        if (issub) {
            return (
                <div style={{ position: "sticky", display: 'flex', bottom: 0, width: '100%', height: '40px', backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center' }}>
                    {ReadButton(book)}
                </div>
            )
        }else if (issub==false) {
            return null
        }
         else if (isfree == 2) {
            return (
                <div style={{ position: "sticky", display: 'flex', bottom: 0, width: '100%', height: '40px', backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center' }}>
                    <Button onClick={showModal} type="primary" size="small">Enter Password</Button>

                </div>
            )
        } else if (isfree == 0 || isfree == 3 || isfree == 4) {
            return (
                <div style={{ position: "sticky", display: 'flex', bottom: 0, width: '100%', height: '40px', backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center' }}>
                    <Button type="primary" size="small">Buy Now</Button>
                </div>
            )
        }else {
            return null
        }
    }
    return (
       <div style={{width:'100%',height:'100vh',flexDirection:'column',position:'relative',alignItems:'center',overflow:'scroll'}}>
           <div style={{width:'100%',height:'400px',flexDirection:'column',position:'relative',alignItems:'center',justifyContent:'center'}}>
               <div className="coverbg" style={{backgroundImage:`url(${bookcover + contentid + "/cover.png"})`}}></div> 
               <div style={{position:'absolute',width:'100%',height:'80%',top:'20%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                   <div className="dcover_cntiner">
                       <img className="dcover" src={bookcover + contentid + "/cover.png"}/>
                   </div>
               </div>
               <LeftOutlined onClick={()=>{history.goBack()}} style={{position:'fixed',top:'10px',left:'10px',fontSize:'20px',color:'#fff',backgroundColor:'#808B96 ',padding:'5px',opacity: '0.5'}}/>
           </div>
           <div style={{display:'flex',marginBottom:'50px',marginTop:'10px',marginLeft:'10px',marginRight:'10px',flexDirection:'column'}}>
               <div style={{textAlign:'center',fontSize:'18px',fontWeight:'bold',letterSpacing:'1px'}}>{book.name}</div>
               <div style={{textAlign:'center',fontSize:'16px'}}><span>isbn:</span>{book.eisbn}</div>
               <div>{book.publisherName}</div>
               <div style={{fontSize:'18px',fontWeight:'bold',letterSpacing:'1px'}}>Description :</div>
               <div style={{textAlign:'justify'}}>{description.replace(/<\/?[^>]+(>|$)/g, "")}</div>
           </div>

           <div style={{position:'fixed',bottom:'0px',width:'100%',height:'50px',flexDirection:'row',display:'flex',padding:'10px'}}>
               <div style={{padding:'7px',display:'flex',alignItems:'center',justifyContent:'center',height:'100%',fontSize:'20px',marginRight:'10px',opacity: '0.7',backgroundColor:'#808B96 '}}>{isfavorite?<HeartFilled onClick={()=>{addOrRemoveLibrary(0)}} style={{color:'red'}} />:<HeartOutlined  onClick={()=>{addOrRemoveLibrary(1)}} />}</div>
               <div style={{padding:'7px',display:'flex',alignItems:'center',justifyContent:'center',height:'100%',fontSize:'20px',marginRight:'10px',opacity: '0.7',backgroundColor:'#808B96 '}}><FilterOutlined/></div> 
               <div style={{height:'100%',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#21618C',color:'#fff',letterSpacing:'1px'}}>Start Reading</div>
           </div>

       </div>
    );

}

export default NewDescription;