import React, { useState, useEffect,useRef } from "react";
import { connect } from "react-redux"
import { ReactReader } from "../modules";
import 'antd/dist/antd.css';
import Epub from "epubjs/lib/index";
import { SettingOutlined,CloseOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import DataStore from '../datastore/Utility';
import { epubURL, bookcover,articleepubURL } from '../api/api'
import Header from '../component/pageheader/pageHeader';
import { Spin, Col, Row, Drawer, Switch, Select, Slider ,Space,Breadcrumb,message,Progress} from 'antd';
import { fontaction,lineaction,nightmodeaction ,fontfamilyaction} from '../redux/actions/dashboardActions';
import { Container, ReaderContainer, FontSizeButton, } from "./Components";
import { useIndexedDB } from 'react-indexed-db';
import {useLocation,useHistory } from 'react-router-dom'
import Bookcover from '../api/images/book.png'
import Axios from "axios";
const { Option } = Select;
// global.ePub = Epub;
const Reader = ({myfontactin,mylineaction,mynightmodeaction,myfontfamilyaction,epubstyle,selecteditem,istocopen,...otherProps}) => {
  const nlocation = useLocation();
  const contentid= nlocation.state.contentid
  const fuzzyid= nlocation.state.fuzzyid
  const isbn=nlocation.state.isbn
  const titel=nlocation.state.name
  const elasticid=otherProps.match.params.elasticid
  const type=nlocation.state.type
  const cover=nlocation.state.cover
  const [page, setPage] = useState('')
  const renditionRef = useRef(null)
  const tocRef = useRef(null)
  const { add , getByIndex} = useIndexedDB('epub');
  const [loading, setloading] = useState(true)
  const [location, setlocation] = useState(0)
  const [localFile, setlocalFile] = useState("")
  const [localName, setlocalName] = useState("")
  const [rendition, setrendition] = useState(null)
  const [visible, setVisible] = useState(false);
  const [fontProps,setfontProps]=useState([])
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });
 const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
 const history = useHistory();
  useEffect(() => {
    getByIndex('name',titel).then(file => {
     if(file){
        localStorage.setItem('currentbook', titel);
        const url = URL.createObjectURL(new Blob([file]))
        localStorage.setItem('epuburl', url);
        let locaion =JSON.parse (localStorage.getItem(titel));
        if(locaion){
          setlocation(locaion.start)
      }
              setlocalName(titel)
        setlocalFile(file.file);
        setloading(false);
     }else{
    const nova_userCredentials = JSON.parse(localStorage.getItem('nova_userCredentials'));
    getdata(nova_userCredentials.apikey);
     }
    });
   }, [])

  const savefile = ({name,file}) => {
    add({ name: name, file: file }).then(
      event => {
        console.log('ID Generated: ', event.target.result);
      },
      error => {
        console.log(error);
      }
    );
  };

  const getdata = (apikey) => {
    message.success('Downloading ....');
    setlocalName(titel)
    let url=epubURL;
    if(type=="article"){
      url=articleepubURL
    }
   
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };
    Axios.get(url + elasticid + ".epub", {responseType: "blob",headers: {'apikey': apikey,'Content-Type': "appl"}, ...options})
    .then (response => response.data)
      .then(async (result) => {
        console.log(result)
        const url = URL.createObjectURL(new Blob([result]))
        localStorage.setItem('epuburl', url);
        if ('arrayBuffer' in result) return await result.arrayBuffer();
        return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject;
        reader.readAsArrayBuffer(result);
    });
     }).then(file=>{

              add({ name:titel,file:file,img:bookcover + fuzzyid + "/"+cover,type:"epub" }).then(
              event => {
                localStorage.setItem('currentbook', titel);
                let locaion =JSON.parse (localStorage.getItem(titel));
                console.log("last locatin",locaion)
                if(locaion){
                  setlocation(locaion.start)
               }      
                setlocalFile(file);
                setloading(false);
              },
              error => {
                console.log(error);
                setlocalFile(file);
                setloading(false);
              }
            )
           
            
          }) 
     .catch(error => {
      message.error('Sorry, something went wrong try again !.');
      setTimeout(() => {
        history.goBack();
      }, 3000);
     })
  }

  const onLocationChanged = location => {
    console.log(location)
    setlocation(location)
   
  };
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

const fontSizeChange = (value) => {
     myfontactin(value)
     styleSetting()
}

  const lineHeightChange = (value) => {
    mylineaction(value)
    styleSetting()

  }
  const nightModeChange = (value) => {
    if(value){
      mynightmodeaction("#ffffff","#000000","100%")
      styleSetting()
    }else{
      mynightmodeaction("#000000","#ffffff","0%")
      styleSetting()
    }
    onClose();

  }
  function onFontChange(value) {
    myfontfamilyaction(value)
    console.log(value)
    styleSetting()
  }

  
  const Settingdrawer = () => {
    return (
      <div style={{ marginLeft: '5px', marginRight: '5px', }}>
         <div style={{ width: '100%', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p style={{ fontWeight: '500',marginTop:'14px',fontSize:"20px",marginLeft:"10px" }}>Setting</p>
        </div>
        <div style={{ width: '100%', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p style={{ fontWeight: '500',marginTop:'14px' }}>Night Mode</p>
          <Switch checked={epubstyle.bgcolor=="#ffffff"?false:true} onChange={nightModeChange} />
        </div>
        <div style={{ width: '100%', height: '70px', display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          <p style={{ fontWeight: '500' }}>Font Size</p>
          <Slider
            defaultValue={epubstyle.fontsize}
            min={1}
            max={20}
            onChange={fontSizeChange}/>
        </div>
        <div style={{ width: '100%', height: '70px', display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          <p style={{ fontWeight: '500' }}>Line Height</p>
          <Slider
            defaultValue={epubstyle.lineheight}
            min={1}
            max={20}
            onChange={lineHeightChange}/>
        </div>
      </div>
    )
  }
  const styleSetting = () => {
    rendition.themes.register("defult", {
     "body": {
        "padding-top": "0px !important",
        "padding-bottom": "0px !important",
        "margin": "0 !important",
        "background":epubstyle.bgcolor,
        "color": epubstyle.color,
        "line-height": epubstyle.lineheight,
        "text-align":"justify",
        "font-size": `${epubstyle.fontsize}px !important`,
      },
      "p": {
        "color": epubstyle.color,
        "line-height": epubstyle.lineheight,
        "font-size": `${epubstyle.fontsize}px !important`,
        "text-align":"justify",
      },
      "table": {
        "margin-left": "auto",
        "margin-right": "auto",
        "display": "block",
        "overflow-x": "auto",
        "border": "0px solid #ddd",
      },
      "a": {
        "text-align":"justify",
         "line-height": epubstyle.lineheight,
        "color": "#1C92E8",
        "text-decoration": "none !important",
        "-webkit-text-fill-color": "inherit !important"
      },
      "img": {
        "filter": `grayscale(${epubstyle.filter})`,
        "background":epubstyle.bgcolor,
       },
     
    });
    rendition.themes.select("defult");
  }


  const getRendition = rendition => {
    setrendition(rendition);
    rendition.themes.register("defult", {
      "body": {
        "padding-top": "0px !important",
        "padding-bottom": "0px !important",
        "margin": "0 !important",
        "background":epubstyle.bgcolor,
        "color": epubstyle.color,
        "line-height": epubstyle.lineheight,
        "font-size": `${epubstyle.fontsize}px !important`,
        "text-align":"justify",
      },
      "p": {
        "color": epubstyle.color,
        "line-height": epubstyle.lineheight,
        "font-size": `${epubstyle.fontsize}px !important`,
        "text-align":"justify",
        "letter-spacing":"1px"
      },
      "table": {
        "background":epubstyle.bgcolor,
        "margin-left": "auto",
        "margin-right": "auto",
        "display": "block",
        "overflow-x": "auto",
        "border": "0px solid #ddd",
      },
      "a": {
        "line-height": epubstyle.lineheight,
        "font-size": `${epubstyle.fontsize}px !important`,
        "text-align":"justify",
        "color": "#1C92E8",
        "text-decoration": "none !important",
        "-webkit-text-fill-color": "inherit !important",
      },
      "img": {
        "filter": `grayscale(${epubstyle.filter})`,
        "background":epubstyle.bgcolor,
       
      },
     
    });
    rendition.themes.select("defult");
  };

  const dataStore = new DataStore();
  if (loading) {
    return (
         <div>
            <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">{" "}</Breadcrumb.Item>
             </Breadcrumb>                   
             <Row>
                <Col style={{ height: '95vh', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                  <p style={{ letterSpacing: '1px', marginTop: '10px',fontFamily:'var(--font)' }}>{titel}</p>
                  <div style={{ height: '80%', width: '100%', display:'flex',alignItems:'center',justifyContent:'center'}}>
                     <img src={bookcover + fuzzyid + "/"+cover} style={{ height: '100%', width: '94%', backgroundColor: '#cccccc', margin:'5px'}} alt="" loading="lazy" />
                  </div>
                  <div style={{ height: '20%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', backgroundColor: "#ffffff",flexDirection:'column',padding:'10px' }}>
                    <Progress percent={downloadInfo.progress} size="default" status="active"  />
                    <p style={{ color: '#000', letterSpacing: '1px', marginTop: '10px', marginLeft: '10px',fontFamily:'var(--font)' }}><span style={{ color: '#1890ff',}}> {formatBytes(downloadInfo.loaded)}</span> / {formatBytes(downloadInfo.total)}</p>
                  </div>
                </Col>
              </Row>
            </div>
         
    )
  }
  return (
   
    <Container>
      <div >
        <ReaderContainer fullscreen={true}>
          <ReactReader dataStore={dataStore}
            url={localFile}
            title={localName}
            location={location}
            locationChanged={onLocationChanged}
            getRendition={getRendition}/>
          <FontSizeButton onClick={showDrawer}>
           {istocopen? null:<SettingOutlined style={{color:epubstyle.color,fontSize:"17px"}} />}
          </FontSizeButton>
        </ReaderContainer>
        <Drawer
          mask={false}
          placement="right"
          closable={true}
          closeIcon={<CloseOutlined style={{color:epubstyle.color}} />}
          onClose={onClose}
          drawerStyle={{color:epubstyle.color,backgroundColor:epubstyle.bgcolor}}
          visible={visible}>
          {Settingdrawer()}
        </Drawer>
      </div>
    </Container>
  );

}
const mapStateToProps = state => ({
  epubstyle: state.App.epubstyle,
  selecteditem: state.App.selecteditem,
  istocopen:state.App.istocopen
})

const mapDispatchToProps = {
  myfontactin:fontaction,
  mylineaction:lineaction,
  mynightmodeaction:nightmodeaction,
  myfontfamilyaction:fontfamilyaction,
}



export default connect(mapStateToProps,mapDispatchToProps)(Reader);
